// 自动加载 `modules` 目录下的路由模块
const modules: any = import.meta.globEager("./modules/**/*.ts");

const basicRoutes = [
  {
    path: "/",
    name: "Login",
    component: () => import("/@/views/system/login/index.vue"),
  },
  {
    path: "/course",
    name: "Course",
    component: () => import("/@/views/course/center/index.vue"),
    children: [],
  },
  {
    path: "/ad1",
    name: "Ad1",
    component: () => import("/@/views/banner/ad1.vue"),
    children: [],
  },
  {
    path: "/ad2",
    name: "Ad2",
    component: () => import("/@/views/banner/ad2.vue"),
    children: [],
  },
  {
    path: "/notice",
    name: "Notice",
    component: () => import("/@/views/notice/list/index.vue"),
    children: [],
  },
  {
    path: "/noticeDetail/:id",
    name: "NoticeDetail",
    component: () => import("/@/views/notice/detail/index.vue"),
    children: [],
  },
  {
    path: "/courseTry",
    name: "CourseTry",
    component: () => import("/@/views/tabBar/myCourse/comps/CourseTry.vue"),
  },
  {
    path: "/orderList",
    name: "OrderList",
    component: () => import("/@/views/tabBar/mine/orderList.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("/@/views/tabBar/mine/privacy.vue"),
  },
  {
    path: "/editInfo",
    name: "EditInfo",
    component: () => import("/@/views/tabBar/mine/editInfo.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("/@/views/tabBar/mine/registration.vue"),
  },
  {
    path: "/bind",
    name: "Bind",
    component: () => import("/@/views/mine/bindStudentNo/index.vue"),
  },
  {
    path: "/courseTry",
    name: "CourseTry",
    component: () => import("/@/views/tabBar/myCourse/comps/CourseTry.vue"),
  },
  {
    path: "/tabBar",
    name: "TabBar",
    component: () => import("/@/layouts/tabBar/index.vue"),
    children: [
      ...modules[
        Object.keys(modules).find((key) => modules[key].name === "tabBar") ||
          "./modules/tabBar.ts"
      ].default,
    ],
  },
];

export default basicRoutes;
