// config router
import { createRouter, createWebHashHistory } from "vue-router";
import basicRoutes from "./routes";
import whiteList from "./whiteList";
// 配置路由到app
// app router生成路由
export const router = createRouter({
  history: createWebHashHistory(), //模式
  routes: basicRoutes,
  strict: true,
  scrollBehavior: () => ({ left: 0, top: 0 }),
});
router.beforeEach((to) => {
  const loginStatus = localStorage.getItem("LOGOIN_STATUS");
  if (to.name === "Login" && loginStatus == "1") {
    return { name: "Home" };
  }
  if (whiteList.includes(to.name) || loginStatus === "1") {
    return true;
  } else {
    return { name: "Home" };
  }
});
router.afterEach(() => {
  document.body.scrollTo(0, 0);
});
export function setupRouter(app) {
  app.use(router);
}
