export default [
  "Home",
  "Login",
  "CourseTry",
  "Notice",
  "NoticeDetail",
  "Registration",
  "Ad1",
  "Ad2",
  "Privacy",
  "Course",
];
