import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import "./common/vant3.css";
import "./common/iconfont-weapp-icon.css";

import { createPinia } from "pinia";
import { registerGlobComp } from "/@/components/registerGlobComp";
import { router, setupRouter } from "./router";
import "amfe-flexible/index.js";
const pinia = createPinia();
async function bootstrap() {
  const app = createApp(App);
  app.use(pinia);
  // 注册全局组件
  registerGlobComp(app);
  // 绑定路由
  setupRouter(app);
  // 路由拦截
  // setupRouterGuard(router);

  await router.isReady();
  // 挂载
  app.mount("#app", true);
}

void bootstrap();
